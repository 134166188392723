.notificationBox{
    cursor: pointer;
    transition-duration: 0.3s;
    padding: 1em 1em;
    gap: 1em;
}

.notificationBox:hover{
    background-color: #dcdcdc;
}
